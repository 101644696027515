<template>
  <NotPermission v-if="!authenticated" />

  <v-container
    v-else
    fluid
    grid-list-lg
  >
      <Breadcrumbs
        :routes="[
          { name: 'Inicio'},
          { name: 'Portada' },
          { name: 'Listado de portadas' }
        ]"
      />
       <v-card>      
        <v-toolbar
          color="grey darken-1"
          dark
          card
        >
          <v-toolbar-title>Listado de Portadas</v-toolbar-title>
          <v-spacer />
          <v-btn
            :to="{ name: 'CreatePortada' }"
            color="primary"
          >
           <svg style="width:24px;height:24px; margin-right:10px;" viewBox="0 0 24 24">
              <path fill="currentColor" d="M20 14H14V20H10V14H4V10H10V4H14V10H20V14Z" />
          </svg>
            Agregar Portada
          </v-btn>
        </v-toolbar>
        <v-container
          fluid
          grid-list-lg
        >
          <v-data-table
                :headers="[
                  { text: 'Posición', value: 'posicion'},
                  { text: 'Imagen-Escritorio'},
                  { text: 'Imagen-Móviles'},
                  { text: 'Nombre', value: 'nombre'},                  
                  { text: 'Link', value: 'descripcion'},
                  { text: 'Acciones'}
                ]"
                :items="portadas"
                :loading="loadingPortadas"
                :rows-per-page-items="[10,25,35,50]"
                class="elevation-2"                
              >
                <tr
                  slot="items"
                  slot-scope="props"
                >  
                  <td class="px-3">
                    {{ props.item.posicion }}
                  </td>                              
                  <td class="px-3">
                    <img
                      v-if="props.item.imagen_escritorio_path != null || props.item.imagen_escritorio_path != ''"                   
											:src="props.item.imagen_escritorio_path"
											alt="imagen"
											style="width:200px; height:70px; object-fit:cover"
										>				
                  </td>
                  <td class="px-3">
										<img                      
                      v-if="props.item.imagen_moviles_path != null || props.item.imagen_moviles_path != ''"
											:src="props.item.imagen_moviles_path"
											alt="imagen"
											style="width:70px; height:70px; object-fit:cover"
										>
                  </td>    
                  <td class="px-3">
                    {{ props.item.nombre }}
                  </td>
                  <td class="px-3">
                    {{ props.item.descripcion ? props.item.descripcion : '' }}
                  </td>                   
                  <td class="px-3">
                    <v-btn
                        class="ma-0"
                        :to="{ name: 'EditPortada', params: { id: props.item.id } }"
                        small
                        icon
                        flat
                        color="info"
                      >
                        <v-icon small>
                          edit
                        </v-icon>
                      </v-btn>
                      <v-btn
                        class="ma-0"
                        small
                        icon
                        flat
                        @click="openModalDeletePortada(props.item)"
                        color="error"
                      >
                        <v-icon small>
                          delete
                        </v-icon>
                      </v-btn>
                  </td>
                  
                </tr>
              </v-data-table>
        </v-container>
      </v-card>
      <ModalDelete />
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {

  metaInfo() {
    return { title: 'Listado de portadas' };
  },

  components: {
    NotPermission: () => import("@/views/errors/NotPermission"),
    Breadcrumbs: () => import('@/components/Breadcrumbs'),
    ModalDelete: () => import('@/views/portadas/ModalDeletePortada')
  },
  data() {
    return {
    };
  },

  computed: {
    ...mapState({
      portadas: state => state.portadas.portadas,
      loadingPortadas: state => state.portadas.loadingPortadas,
    }),
    ...mapGetters({
      authenticated: 'auth/check',
      user: 'auth/user',
    }),
  },
  created() {
    this.getPortadas();
  },
  methods: {
    ...mapActions({
      getPortadas: 'portadas/getPortadas',
      replaceShowModalDeletePortada: 'portadas/replaceShowModalDeletePortada',
      replaceCurrentPortada: 'portadas/replaceCurrentPortada',
    }),
    openModalDeletePortada (portada) {
      this.replaceCurrentPortada({ portada })
      this.replaceShowModalDeletePortada({ status: true })
    }

  },
};
</script>
