var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.authenticated)?_c('NotPermission'):_c('v-container',{attrs:{"fluid":"","grid-list-lg":""}},[_c('Breadcrumbs',{attrs:{"routes":[
          { name: 'Inicio'},
          { name: 'Portada' },
          { name: 'Listado de portadas' }
        ]}}),_c('v-card',[_c('v-toolbar',{attrs:{"color":"grey darken-1","dark":"","card":""}},[_c('v-toolbar-title',[_vm._v("Listado de Portadas")]),_c('v-spacer'),_c('v-btn',{attrs:{"to":{ name: 'CreatePortada' },"color":"primary"}},[_c('svg',{staticStyle:{"width":"24px","height":"24px","margin-right":"10px"},attrs:{"viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill":"currentColor","d":"M20 14H14V20H10V14H4V10H10V4H14V10H20V14Z"}})]),_vm._v(" Agregar Portada ")])],1),_c('v-container',{attrs:{"fluid":"","grid-list-lg":""}},[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":[
                  { text: 'Posición', value: 'posicion'},
                  { text: 'Imagen-Escritorio'},
                  { text: 'Imagen-Móviles'},
                  { text: 'Nombre', value: 'nombre'},                  
                  { text: 'Link', value: 'descripcion'},
                  { text: 'Acciones'}
                ],"items":_vm.portadas,"loading":_vm.loadingPortadas,"rows-per-page-items":[10,25,35,50]},scopedSlots:_vm._u([{key:"items",fn:function(props){return _c('tr',{},[_c('td',{staticClass:"px-3"},[_vm._v(" "+_vm._s(props.item.posicion)+" ")]),_c('td',{staticClass:"px-3"},[(props.item.imagen_escritorio_path != null || props.item.imagen_escritorio_path != '')?_c('img',{staticStyle:{"width":"200px","height":"70px","object-fit":"cover"},attrs:{"src":props.item.imagen_escritorio_path,"alt":"imagen"}}):_vm._e()]),_c('td',{staticClass:"px-3"},[(props.item.imagen_moviles_path != null || props.item.imagen_moviles_path != '')?_c('img',{staticStyle:{"width":"70px","height":"70px","object-fit":"cover"},attrs:{"src":props.item.imagen_moviles_path,"alt":"imagen"}}):_vm._e()]),_c('td',{staticClass:"px-3"},[_vm._v(" "+_vm._s(props.item.nombre)+" ")]),_c('td',{staticClass:"px-3"},[_vm._v(" "+_vm._s(props.item.descripcion ? props.item.descripcion : '')+" ")]),_c('td',{staticClass:"px-3"},[_c('v-btn',{staticClass:"ma-0",attrs:{"to":{ name: 'EditPortada', params: { id: props.item.id } },"small":"","icon":"","flat":"","color":"info"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" edit ")])],1),_c('v-btn',{staticClass:"ma-0",attrs:{"small":"","icon":"","flat":"","color":"error"},on:{"click":function($event){return _vm.openModalDeletePortada(props.item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" delete ")])],1)],1)])}}])})],1)],1),_c('ModalDelete')],1)}
var staticRenderFns = []

export { render, staticRenderFns }